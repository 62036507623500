.container {
  padding: 0 2rem;
}

/* 
  TODO... how to add something to main only on main?
*/

@media screen and (max-width: 650px) {
  .container.home {
    margin: -8px !important;
    width: calc(100% + 16px) !important;
  }
}

.container.home {
  margin: -24px;
  width: calc(100% + 48px);
  padding: 4rem 0rem;
  background: rgb(255, 255, 255);
  background: linear-gradient(0deg, #1c2a3f 80%, #24354f 90%);
}

.footer {
  display: flex;
  flex: 1;
  padding: 2rem 0;
  border-top: 1px solid #eaeaea;
  justify-content: center;
  align-items: center;
}

.headline {
  /* color: rgb(25,64,115); */
  background: linear-gradient(90deg, #ff5f6d 0%, #ffc371 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 20vw;
  line-height: 20vw;
  margin: 0;
  text-align: center;
}

.stock {
  background-image: url("../public/images/pizza_2.jpeg");
  background-size: cover;
}

.stock2 {
  background-image: url("../public/images/books.jpeg");
  background-size: cover;
}

.nav {
  background: #ff5f6d; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to top,
    #ffc371,
    #ff5f6d
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to top, #ffc371, #ff5f6d);
}
